.stepper {

    .coin1 {
        display: none;
    }

    h3 {
        text-align: center;
        margin-bottom: 80px;
    }

    .stepper-container {
        position: relative;

        .coin2 {
            bottom: -56%;
            right: 0%;
        }


        .phone {
            position: relative;
            //left: 172px;
            transform: translateX(172px);
            width: 275px;
            height: 480px;

            .main {
                width: 100%;
                height: 100%;
            }


            .line-mbl {
                top: 69px;
                left: -74px;
                transform: rotate(-5.148deg);
            }

            .line-tbl {
                display: none;
            }

            .line-big {
                display: none;
                top: 122px;
                left: -118px;
            }

            .circle {
                top: 48px;
                left: 88px;
            }


        }

        .step {

            &.white {
                border-radius: 24px;
                background: var(--text-100);
                padding: 16px 24px 24px;
                color: var(--bg-100);
            }

            &.step1 {
                top: -32px;
                width: 162px;
                height: 128px;
                border-radius: 24px;
                background: rgba(255, 255, 255, 0.15);
                padding: 16px 24px 24px 24px;

                .telegram {
                    top: -38px;
                    right: -32px;
                }
            }

            &.step2 {
                top: 160px;
                width: 202px;
                height: 128px;

                img {
                    transform: rotate(12deg);
                    top: -55px;
                    right: -15px;
                    border-radius: 16px;
                    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
                }
            }

            &.step3 {
                width: 248px;
                top: 336px;
                left: 56px;

                img {
                    transform: rotate(-8deg);
                    top: -78px;
                    right: -28px;
                    z-index: 1;
                    border-radius: 24px;
                    box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25);
                }
            }

            &.step4 {
                width: 192px;
                bottom: -232px;


                .twitter {
                    top: -48px;
                    left: 70px;
                    animation-name: moving1;
                }

                .facebook {
                    bottom: -48px;
                    left: 0;
                }

            }



            .info {
                line-height: 24px;
                font-size: 16px;

                .name {
                    font-weight: 800;
                    margin-bottom: 16px;
                }

                .comment {
                    font-weight: 400;

                    b {
                        font-weight: 700;
                    }
                }

                position: relative;
            }

        }
    }
}

@media screen and (max-width: 375px) {

    .stepper {
        .stepper-container {
            .step {
                &.step3 {
                    width: 248px;
                    top: 336px;
                    left: 0px;
                }
            }
        }

    }
}

@media screen and (min-width: 500px) {
    .stepper {
        .stepper-container {
            transition: transform 0.5s ease;
            transform: translateX(30px);
        }
    }
}

@media screen and (min-width: 550px) {
    .stepper {
        .stepper-container {
            transform: translateX(80px);
        }
    }
}

@media screen and (min-width: 600px) {
    .stepper {
        .stepper-container {
            transform: translateX(130px);
        }
    }
}

@media screen and (min-width: 670px) {

    .stepper {
        display: flex;
        flex-direction: column;

        .coin1 {
            display: block;
            bottom: 9%;
            left: 14%;
        }

        h3 {
            text-align: left;
            margin-bottom: var(--h3-mb);
        }

        .stepper-container {
            transform: translateX(0px);
            align-self: center;
            position: relative;
            margin-bottom: -12px;

            .coin2 {
                bottom: 45%;
                right: -49%;
            }


            .phone {
                //left: 172px;
                transform: translateX(0px);
                width: 367.713px;
                height: 640px;

                .main {
                    width: 100%;
                    height: 100%;
                }

                .line-mbl {
                    display: none;
                }

                .line-tbl {
                    display: block;
                    top: 134px;
                    left: -36px;
                    transform: rotate(-8.393deg);
                }


                .circle {
                    top: 88px;
                    left: 173px;
                }


            }

            .step {

                &.white {
                    padding: 24px 32px 32px;
                }

                &.step1 {
                    top: 0px;
                    left: -140px;
                    width: 206px;
                    height: 176px;
                    padding: 24px 32px 32px 32px;

                    .telegram {
                        width: 80px;
                        height: 80px;
                        top: -38px;
                        right: -32px;
                    }
                }

                &.step2 {
                    top: 80px;
                    right: -140px;
                    width: 249px;
                    height: 176px;

                    img {
                        top: -56px;
                        right: 8px;
                        box-shadow: -4px 4px 16px 0px rgba(0, 0, 0, 0.25);
                    }
                }

                &.step3 {
                    width: 304px;
                    top: 312px;
                    left: -140px;

                    img {
                        height: 144px;
                        width: 144px;
                        top: -107px;
                        right: 7px;
                    }
                }

                &.step4 {
                    width: 264px;
                    bottom: -32px;
                    right: -145px;


                    .twitter {
                        top: -66px;
                        left: 97px;
                        width: 120px;
                        height: 120px;
                    }

                    .facebook {
                        bottom: -65px;
                        left: 160px;
                        width: 72px;
                        height: 72px;
                    }

                }



                .info {
                    line-height: 32px;
                    font-size: 20px;

                    .name {
                        margin-bottom: 24px;
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 1023px) {



    .main-sections .section .stepper {
        padding-bottom: 0;
    }

    .stepper {

        .stepper-container {

            margin-bottom: 0px;

            .coin2 {
                bottom: 49%;
                right: -34%;
            }


            .phone {
                width: 574.552px;
                height: 1000px;

                .line-tbl {
                    display: none;
                }

                .line-big {
                    display: block;
                    top: 235px;
                    left: -143px;
                }



                .circle {
                    top: 234px;
                    left: 135px;
                }


            }

            .step {

                &.step1 {
                    top: 56px;
                    left: -192px;
                    width: 234px;
                    height: 200px;
                    border-radius: 32px;

                    .telegram {
                        top: -38px;
                        right: -32px;
                    }
                }

                &.step2 {
                    top: 141px;
                    right: -220px;
                    width: 366px;
                    height: 160px;

                    img {
                        height: 88px;
                        width: 88px;
                        right: 0;

                    }
                }

                &.step3 {
                    width: 384px;
                    top: 489px;
                    left: -234px;

                    img {
                        height: 160px;
                        width: 160px;
                        top: -107px;
                        right: 7px;
                    }
                }

                &.step4 {
                    width: 384px;
                    bottom: 116px;
                    right: -220px;


                    .facebook {
                        bottom: -65px;
                        left: 180px;
                        width: 72px;
                        height: 72px;
                    }

                }



                .info {
                    line-height: 40px;
                    font-size: 24px;
                }

            }
        }
    }
}