/* General styles */
body {
	position: relative;
	margin: 0 auto;
	-webkit-overflow-scrolling: touch;
	scroll-behavior: smooth;
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: var(--color-white);
	//overflow-x: clip;
}



[data-absolute] {
	position: absolute;
}

/* Headings */

.text-200 {
	font-size: var(--p-size);
	font-weight: 300;
	line-height: var(--p-lh);
	color: var(--text-200);
}

h1 {
	font-size: var(--h1-size);
	font-weight: bold;
	line-height: var(--h1-lh);
	margin-bottom: var(--h1-mb);
}

h2 {
	font-size: var(--h2-size);
	font-weight: bold;
	line-height: var(--h2-lh);
	margin-bottom: var(--h2-mb);
	/* margin-left: var(--mbl-mg);
	margin-right: var(--mbl-mg); */
}

h3 {
	font-size: var(--h3-size);
	line-height: var(--h3-lh);
	margin-bottom: var(--h3-mb);
}

h4 {
	font-size: var(--h4-size);
	font-weight: 300;
	line-height: var(--h4-lh);
	margin-bottom: var(--h4-mb);
}

h5 {
	font-size: var(--h5-size);
	font-weight: 600;
	line-height: var(--h5-lh);
	margin-bottom: var(--h5-mb);
}

/* Paragraphs */
p {
	//max-width: var(--texts-width);
	font-size: var(--p-size);
	font-weight: 400;
	line-height: var(--p-lh);
	margin-bottom: var(--p-mb);
	color: var(--text-200);

	&.no-max {
		max-width: 800px;
	}
}



/* Images */
img {
	pointer-events: none;
	user-select: none;
}


/* Buttons */
button.button,
a.button {
	padding: 16px 40px;
	border-radius: 48px;
	background-color: var(--color-white);
	color: var(--color-black);
	flex-grow: 0;
	font-size: 16px;
	line-height: 24px;
	font-weight: 600;
	text-align: center;
	position: relative;
	cursor: pointer;


	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 48px;
		opacity: 0;
		z-index: -1;
		filter: blur(20px);
		background: var(--button-gradient);
		transition: opacity 0.5s ease;
	}

	&:hover {
		&::before {
			opacity: 1;
		}
	}



	&.small {
		width: 200px;
	}

	&.large {
		width: var(--btn-large-w);
	}

	&.full {
		width: var(--btn-full-w);
	}

	&.headerBtn {
		padding: var(--btn-padding);
		font-size: var(--btn-size);
		line-height: var(--btn-lh);
		border-radius: var(--btn-radius);
	}

	&:disabled {
		opacity: 0.4;
		cursor: not-allowed;

		&:before {
			opacity: 0 !important;
		}
	}

	&:active {
		.gradient {
			opacity: 1;
		}
	}
}

@media(hover: hover) and (pointer: fine) {

	button.button,
	a.button {
		&:hover {
			.gradient {
				opacity: 1;
			}
		}
	}
}

/* Glass */
.glass {
	-webkit-backdrop-filter: var(--glass-filter) !important;
	backdrop-filter: var(--glass-filter) !important;
	border: var(--glass-border) !important;
	background-color: var(--glass-background) !important;
	color: var(--color-white) !important;
}








/* Media Queries */
@media screen and (max-width: 1023px) {
	p.no-max-tbl {
		max-width: none;
	}
}


.reveal {
	opacity: 0;
	transform: translateY(40px);
	transition: opacity 0.55s ease, transform 0.55s ease;

	&.order-1 {
		transition-delay: 0.1s;
		//animation-delay: 0.1s;
	}

	&.order-2 {
		//animation-delay: 0.2s;
		transition-delay: 0.2s;
	}

	&.order-3 {
		transition-delay: 0.3s;
		//animation-delay: 0.3s;
	}

	&.order-4 {
		transition-delay: 0.4s;
		//animation-delay: 0.4s;
	}


	&.order-5 {
		transition-delay: 0.5s;
		//animation-delay: 0.5s;
	}

	&.order-6 {
		transition-delay: 0.6s;
		//animation-delay: 0.6s;
	}

	&.order-7 {
		transition-delay: 0.7s;
		//	animation-delay: 0.7s;
	}

	&.order-8 {
		transition-delay: 0.8s;
		//animation-delay: 0.8s;
	}

	&.order-9 {
		transition-delay: 0.9s;
		//animation-delay: 0.9s;
	}

	&.order-10 {
		transition-delay: 1s;
		//animation-delay: 1s;
	}
}

.animated {
	opacity: 1;
	transform: none;
	transition: opacity 0.55s ease, transform 0.55s ease;
}

.accent {
	color: var(--primary-200) !important;
}

/* a.accent {
	text-decoration: underline;
} */

.bold {
	font-weight: bold !important;
}

span,
a {
	&.accent {
		color: var(--accent-100) !important;
	}

	&.primary-100 {
		color: var(--primary-100) !important;
	}

	&.primary-200 {
		color: var(--primary-200) !important;
	}

	&.primary-300 {
		color: var(--primary-300) !important;
	}
}
