body {
    position: relative;
    margin: 0 auto;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: var(--color-white);
}




.main-sections {
    padding: 0 var(--mg-lr);
    flex-direction: column;
    top: 0;
    display: flex;
    position: relative;
    overflow-x: hidden;

    .space {
        height: var(--spacerHeight);
        ;
    }

    &.privacy {
        overflow-x: initial;

        .section-privacy {
            padding-bottom: 48px;
            max-width: 1040px;
            width: 100%;

            ul {
                margin-bottom: 24px;

                li {
                    list-style: inside !important;
                    margin-bottom: 8px;
                }
            }

            &:last-of-type {
                padding-bottom: 100px;
            }
        }
    }


    .section {
        margin: 0;
        padding-bottom: var(--sectionBottom);
        height: 100%;
        /* width: 100%; */
        scroll-margin-top: var(--headerHeight);
        position: relative;

        &.stepper {
            padding-bottom: var(--stepperBottom);
        }

        &.last {
            padding-bottom: var(--sectionLastBottom);
        }

    }
}




@media screen and (max-width:375px) {
    .main-sections {
        max-width: 375px;
    }
}








@media screen and (min-width:1180px) {
    .main-sections {
        display: flex;
        align-items: center;
        margin-left: none;

        .section {
            width: 1040px;
        }
    }
}