.hero {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .button {
        margin-top: 24px;
    }

    .image-cards {
        position: relative;
        margin-top: 64px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;
        width: 100%;

        .image-card {
            width: 47%;
            height: auto;
        }

        .instagram {
            top: -91px;
            left: -55px;
            width: 80px;
            height: 80px;
        }
    }

    .coin1 {
        top: -4%;
        left: -6%;
    }

    .coin3 {
        top: -154px;
        right: -11%;
        z-index: -1;
    }

    .coin3-small {
        bottom: 9%;
        right: 3%;
        transform: rotate(180deg);
    }


}

@media screen and (max-width: 390px) {
    .hero {
        .image-cards {
            gap: 8px;
        }
    }
}


@media screen and (min-width: 670px) {
    .hero {
        text-align: left;
        align-items: flex-start;

        .button {
            margin-top: 48px;
        }

        .image-cards {
            margin-top: 80px;
            flex-wrap: nowrap;
            gap: 24px;

            .image-card {
                max-width: 200px;
                width: 33%;
                height: auto;
            }

            .instagram {
                top: -95px;
                left: 50%;
            }

            .image-3 {
                transform: translateY(-136px);
            }

            .image-4 {
                display: none;
            }
        }

        .coin1 {
            top: -4%;
            left: initial;
            right: -6%;
        }

        .coin3 {
            top: -196%;
            right: 4%;
        }
    }
}

@media screen and (min-width: 1023px) {
    .desktop-bold {
        font-weight: bold;
    }

    .hero {

        h2,
        p {
            max-width: 776px;
        }

        .image-cards {
            flex-wrap: nowrap;
            gap: 16px;

            .image-card {
                max-width: 248px;
            }

            .instagram {
                top: initial;
                bottom: -30%;
                left: -10%;
            }

            .image-3 {
                transform: translateY(0px);
            }

            .image-4 {
                display: block;
                transform: translateY(-136px);
            }
        }

        .coin1 {
            top: -4%;
            left: initial;
            right: 13%;
        }

        .coin3 {
            top: -158%;
            right: 4%;
        }

        .coin3-small {
            bottom: 19%;
        }
    }
}