.last {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .privacy {
        color: var(--text-200, #D4D4D8);
        text-align: center;
        font-family: Inter;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 18px;
        /* 150% */
        text-decoration-line: underline;
    }

    .coin3 {
        bottom: 33%;
        left: -4%;
        transform: rotate(270deg);
    }


    p {
        margin-top: 120px;
    }
}

@media screen and (min-width: 670px) {

    .last {
        .coin3 {
            bottom: 35%;
            left: 6%;
        }



        p {
            margin-top: 160px;
        }
    }
}


@media screen and (min-width: 1023px) {

    .last {
        .coin3 {
            bottom: 55%;
            left: -3%;
        }

    }
}