.object {
    will-change: transform;
    position: absolute;

    >img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.object:nth-child(1),
.object:nth-child(5),
.object:nth-child(10) {
    animation: moving1 38s ease-in-out infinite;
}

.object:nth-child(2),
.object:nth-child(6),
.object:nth-child(9) {
    animation: moving2 35s ease-in-out infinite reverse;
}

.object:nth-child(3),
.object:nth-child(4) {
    animation: moving3 32s ease-in-out infinite;
}

.object:nth-child(7),
.object:nth-child(8) {
    animation: moving4 43s linear infinite reverse;
}